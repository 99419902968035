<template>
	<div class="card collection-card" v-if="collection!=null">
		<div class="card-detail">
			<div class="row mb10">
				<h6 class="col-7 collection-name">{{ collection.title }}</h6>
				<span class="col-5" style="text-align: right;">{{ numberOfContents }} Stationen</span>
			</div>
			<ul class="card-detail__features">
				<li>
					<img src="/assets/icons/width.svg" alt="icon" >
					<span>{{ distance }} km</span>
				</li>
				<li>
					<img src="/assets/icons/walk.svg" height="18px;" alt="icon" >
					<span>{{ footPath }} min</span>
				</li>
				<li>
					<img src="/assets/icons/bicycle.svg" alt="icon" >
					<span>{{ bikePath }} min</span>
				</li>
			</ul>

		</div>

		<a :href="linkTo" ref="focusPopup" class="show-more-btn btn btn-sm mt10 mb10 btn-focus-design" style="margin: 0 auto;">Mehr Infos</a>

	</div>
</template>

<script>
	import { getFieldValues } from '@/utils/helpers';

	export default {
		name: "CardCollection",
		props: {
			collection: {
				type: Object,
			default: null,
				required: true
			}
		},
		watch: {
			collection: {
				handler(val){
					if(this.collection.highlight == true){
						setTimeout(() => {
							this.$refs.focusPopup.focus();
						}, 500);
					}
				},
				deep: true
			}
		},
		computed: {
			numberOfContents(){
				if(this.collection!=null){
					let stations = getFieldValues(this.collection, 'beziehungen_zu_anderen_inhalten');
					if(stations !== null){
						return stations.length;
					}
				}
				return 0;
			},
			linkTo(){
				return "/touren/"+this.collection.id;
			},
			bikePath: function() {
				if(this.collection!==null) {
					return getFieldValues(this.collection,'dauer_mit_dem_rad_in_minuten');
				}
				return null;
			},
			footPath: function() {
				if(this.collection!==null) {
					return getFieldValues(this.collection,'dauer_zu_fu_in_minuten');
				}
				return null;
			},
			distance(){
				if(this.collection!==null){
					return getFieldValues(this.collection,'weglnge_in_km');
				}
				return null;
			},
		},
		methods: {
		}
	}
</script>

<style lang="scss" scoped>
	@import '@/scss/_variables.scss';

	.collection-card{
		position: relative;
		border: none;
		min-width: 264px;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;


		.show-more-btn{
			color: #FFF;

			&:hover:not(:focus), &:hover:not(:focus-visible) {
				color: $primary;
				background-color: #FFF;
			}

			&:focus, &:focus-visible {
				background-color: $primary;
				color: #FFF;
			}
		}

		.collection-name{
			font-size: 14px;
		}

		.card-img {
			position: relative;
			overflow: hidden;
			min-height: 100px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;

			img {
				width: 100%;
				overflow: hidden;
			}
		}

		.card-detail {
			padding: 10px 20px;

			.title {
				font-size: 14px;
				margin-bottom: 10px;
			}

			&__features {
				display: flex;

				li {
					flex: 1;
					display: inline-flex;
					vertical-align: middle;
					align-items: center;

					i, img {
						margin-right: 10px;
					}

					img {
						display: inline-block;
						max-height: 24px;
					}

					&.highlight {
						color: $success !important;

						span {
							font-weight: 600;
						}
					}
				}
			}
		}
	}

</style>
