<template>
  <div style="height: 100%;">

    <l-map ref="theMap" class="map" id="theMap" :zoom="karte.zoom" :center="karte.center" :options="karte.mapOptions">

      <l-tile-layer :name="karte.selectedTile.name" :url="karte.selectedTile.url" :attribution="karte.selectedTile.attribution" layer-type="base"/>

      <!--Anzeige für einen Marker-->
      <div v-if="collections!=null"> 
        <div v-for="collection in collections" :key="collection.id">
          <l-marker :id="'collection-marker-'+collection.id" :ref="'collection-marker-'+collection.id" v-if="showThisMarker(collection)" :lat-lng="getFirstLocation(collection)" :icon="getSymbolIcon('marker')" @click="highlightAndShowThisCollectionPath(collection)">
            <l-popup class="collection-popup">
              <MarkerCollection :collection="collection"/>
            </l-popup>
          </l-marker>
        </div>
      </div>

      <div v-if="tour.pathPoints!=null && modus=='showTour'"> 

        <l-marker v-if="tour.endPoint!==null" :lat-lng="tour.endPoint" :icon="getSymbolIcon('marker')"></l-marker>

        <l-polyline v-if="tour.pathPoints!=null && modus=='showTour'" :lat-lngs="tour.pathPoints" :color="'#E5321B'"></l-polyline>

      </div>

    </l-map>
  </div>
</template>

<script>
  import { getFieldValues, json_decode } from '@/utils/helpers';
  import 'leaflet/dist/leaflet.css';
  import { latLng, icon, latLngBounds} from 'leaflet';
  import MarkerCollection from './MarkerCollection.vue';
  import { screenSizeMixin } from '@/mixins/screenSizeMixin';
  import { LMap, LTileLayer, LMarker, LPopup ,LPolyline } from "vue2-leaflet";

  export default {
    name: 'TourenMapView',
    mixins: [screenSizeMixin],
    components: {
      LPolyline,
      MarkerCollection,
      LTileLayer,
      LPopup,
      LMarker,
      LMap,
    },
    props: {
      height: {
        type: String,
      default: '700px',
      },
      collections: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        modus: 'showMarkers',
        tour: {
          endPoint: null,
          pathPoints: null,
          selectedCollection: null
        },
        karte: {
          modus: false,
          zoom: 12,
          center: latLng(54.35879047390532, 10.169230974009567),
          mapOptions: {
            zoomSnap: 0.5,
            zoomControl: true,
            touchZoom: true,
            dragging: true,
            doubleClickZoom: true,
            scrollWheelZoom: true,
            keyboard: true,
          },
          clusterOptions: {
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: false,
          },
          selectedTile: {
           url:"https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
           attribution:"&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors &copy; <a href=\"https://carto.com/attributions\">CARTO</a>"
         },
       },
     };
   },
   computed: {
    markerBounds(){
     var bounds = [];
     if(this.tour.pathPoints!=null && this.tour.pathPoints.length>2){
      for(var j = 0; j<this.tour.pathPoints.length; j++){
        var coords = latLng(this.tour.pathPoints[j]);
        bounds.push(coords);
      }
      return latLngBounds(bounds);
    }
    return null;
  },
  markerData(){
    if(this.addressObj!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'latitude') && this.addressObj.latitude!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'longitude') && this.addressObj.longitude!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'street') && this.addressObj.street!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'zipcode') && this.addressObj.zipcode!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'city') && this.addressObj.city!=null){

      var mapMarker = {lat:this.addressObj.latitude, long:this.addressObj.longitude, street:this.addressObj.street, zipcode:this.addressObj.zipcode, city:this.addressObj.city, latLng:latLng(this.addressObj.latitude,this.addressObj.longitude)}; 
      return mapMarker;
    }
    return null;
  },
},
mounted() {
},
methods: {
  getSymbolIcon(elem){
    var customIcon = null;
    if(elem == 'marker'){
      customIcon = icon({
        iconUrl: '/assets/icons/marker.svg',
        shadowUrl: '',
          iconSize: [60, 70],/* size of the icon */
          iconAnchor: [30, 60], /* point of the icon which will correspond to marker's location */
        popupAnchor: [0, -50],
      });
    }
    else{
      customIcon = icon({
        iconUrl: '/assets/icons/marker-point.svg',
        shadowUrl: '',
          iconSize: [30, 30],/* size of the icon */
          iconAnchor: [15, 15], /* point of the icon which will correspond to marker's location */
        popupAnchor: [7, 20],
      });
    }
    return customIcon;
  },
  getFirstLocation(collection){
    if(collection!=null){
      var coordinates = json_decode(getFieldValues(collection,'gpx-datei'));
      if(coordinates !== null){
        return latLng(coordinates[0].lat[0],coordinates[0].lon[0]);
      }
    }
    return null;
  },
  getLastLocation(collection){
    if(collection!=null){
      var coordinates = json_decode(getFieldValues(collection,'gpx-datei'));
      if(coordinates !== null){
        return latLng(coordinates[coordinates.length-1].lat[0],coordinates[coordinates.length-1].lon[0]);
      }
    }
    return null;
  },
  showThisMarker(collection){
    if(this.tour.selectedCollection == null){
      return true;
    }
    else if(this.tour.selectedCollection.id == collection.id){
      return true;
    }
    return false;
  },
  openPopup(collection){
      //console.log(this.$refs.theMap);

    this.tour.selectedCollection = null;
    this.tour.pathPoints = null;
    this.modus = "showMarkers";

    this.$nextTick(() => {
      if('collection-marker-'+collection.id in this.$refs){
        this.karte.zoom=12;
        this.$refs['collection-marker-'+collection.id][0].mapObject.openPopup();
        this.karte.center = this.getFirstLocation(collection);
        this.showCollectionOnMap(collection);
      }
    });

  },
  showCollectionOnMap(collection){
    if(collection!=null){
      var pathPoints =  json_decode(getFieldValues(collection,'gpx-datei'));
      var latlngs = [];
      for(var i = 0; i<pathPoints.length; i++){
        latlngs.push(latLng(pathPoints[i].lat[0],pathPoints[i].lon[0]));
      }
      this.tour.selectedCollection = collection;
      this.tour.pathPoints = latlngs;
      this.tour.endPoint = this.getLastLocation(collection);


      this.$nextTick(()=>{
        /*only the devil knows why that fitBounds stuff does not work on mobile - so we'll just stick with using that thing for desktop-sized maps*/
        if(!this.$isMobileScreen){
          this.$refs.theMap.fitBounds(this.markerBounds, {
            padding: [250, 250]
          });
        }
      })


        /*switch modus to showTour*/
      this.modus = "showTour";
    }
  },
  highlightAndShowThisCollectionPath(collection){
    this.showCollectionOnMap(collection);
    this.$emit('highlight',{sender:'map', collection: collection});
  }
},
};
</script>
<style lang="scss" scoped>

  .leaflet-container .leaflet-marker-pane img{
    -webkit-filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .5));
    filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .5));
  }

</style>

